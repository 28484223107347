<template>
  <div class="module" :style="{ '--bg': hlp_background_color }">
    <div class="container module-inner">
      <div v-if="hasPreview && showPreview" class="preview-container">
        <HlpVideo
          :source-set="preview_asset.source_set"
          :controls="false"
          :autoplay="true"
          :muted="true"
          :pause-on-scroll-out="preview_asset.settings?.pause_on_scroll_out"
          :reset-on-scroll-out="preview_asset.settings?.reset_on_scroll_out"
          :loop="preview_asset.settings?.loop"
          :lazy-load="preview_asset.settings?.lazy_load"
        />
        <div class="custom-control-overlay" @click="toggleShowPrimaryVideo">
          <div class="custom-play-control">Watch the Film</div>
        </div>
      </div>

      <component
        :is="preview_asset.open_primary_video_in_modal_view && hasPreview ? modalComponent : 'div'"
        v-if="showPrimaryVideo || !hasPreview"
        v-bind="
          preview_asset.open_primary_video_in_modal_view
            ? { lockBodyOnOpen: false, onClosed: toggleShowPrimaryVideo }
            : { class: 'primary-video-container' }
        "
      >
        <HlpVideo
          v-if="cloudinary_video.source_set.length !== 0"
          :source-set="cloudinary_video.source_set"
          :autoplay="cloudinary_video.settings?.autoplay"
          :loop="cloudinary_video.settings?.loop"
          :muted="cloudinary_video.settings?.muted"
          :controls="cloudinary_video.settings?.controls"
          :pause-on-scroll-out="cloudinary_video.settings?.pause_on_scroll_out"
          :reset-on-scroll-out="cloudinary_video.settings?.reset_on_scroll_out"
          :lazy_load="cloudinary_video.settings?.lazy_load"
        />

        <YouTubeEmbed v-if="youtube_video.video_id" :video-id="youtube_video.video_id" />
      </component>
    </div>
  </div>
  <div v-if="false"><!-- prevent props from being passed to the root element --></div>
</template>

<script setup lang="ts">
import YouTubeEmbed from './YouTubeEmbed.vue';
import HlpVideo from './HlpVideo.vue';
import { AssetCloudinary } from '~/types/contentstack';
import { Breakpoint } from '~/configs/breakpoints';

const props = defineProps<{
  // eslint-disable-next-line vue/prop-name-casing
  hlp_background_color?: string;

  // eslint-disable-next-line vue/prop-name-casing
  preview_asset: {
    source_set: {
      cloudinary_asset: AssetCloudinary[];
      breakpoint: Breakpoint;
      transform?: {
        width: number;
        height: number;
      };
    }[];
    settings: {
      loop?: boolean;
      pause_on_scroll_out?: boolean;
      reset_on_scroll_out?: boolean;
      lazy_load?: boolean;
    };
    open_primary_video_in_modal_view?: boolean;
  };

  // eslint-disable-next-line vue/prop-name-casing
  cloudinary_video: {
    source_set: {
      cloudinary_asset: AssetCloudinary[];
      breakpoint: Breakpoint;
      transform?: {
        width: number;
        height: number;
      };
    }[];
    settings: {
      autoplay?: boolean;
      loop?: boolean;
      muted?: boolean;
      controls?: boolean;
      pause_on_scroll_out?: boolean;
      reset_on_scroll_out?: boolean;
      lazy_load?: boolean;
    };
  };

  // eslint-disable-next-line vue/prop-name-casing
  youtube_video: {
    video_id: string;
  };
}>();

const modalComponent = resolveComponent('ModalWindow');
const hasPreview = computed(() => props.preview_asset.source_set.length !== 0);
const showPrimaryVideo = ref(false);
const showPreview = ref(!!hasPreview);

const toggleShowPrimaryVideo = () => {
  showPrimaryVideo.value = !showPrimaryVideo.value;

  if (!props.preview_asset.open_primary_video_in_modal_view && showPrimaryVideo.value === true) {
    showPreview.value = false;
  }
};
</script>

<style lang="scss" scoped>
.module {
  display: grid;
  place-items: center;
  background-color: var(--bg);
  padding-block: 3.75rem;
  padding-inline: 1rem;
}

.container {
  position: relative;
  width: 100%;
  max-width: calc(local-vars.$xxl-content-max-width - 4rem);
  border-radius: 0.5rem;
  overflow: hidden;
}

.module-inner {
  display: grid;
  grid-template-areas: 'stack';
}

.overlay {
  position: absolute;
  inset: 0;
}

.modal {
  z-index: 99;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
}

.primary-video-container {
  grid-area: stack;
}

.preview-container {
  grid-area: stack;
}

.custom-control-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.custom-play-control {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  bottom: 0;
  color: #fff;
  font-size: 0.875rem;
  margin: 1rem;
  cursor: pointer;
  transition: opacity 0.3s;
}
.custom-play-control:before {
  content: '';
  width: 32px;
  height: 32px;
  background-color: #fff;
  border-radius: 100%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="black" viewBox="0 0 24 24" stroke="none"><path d="M0 0h24v24H0z" fill="none"></path><path d="M6 4v16a1 1 0 0 0 1.524 .852l13 -8a1 1 0 0 0 0 -1.704l-13 -8a1 1 0 0 0 -1.524 .852z"></path></svg>');
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
